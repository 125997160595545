import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {getAccounts, getMessage, getMessages, postMessage} from "../services/app.service";

export default function Chat(props: any) {
    const [rows, setRows] = useState<Array<any>>([]);
    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [messages, setMessages] = useState<Array<any>>([]);
    const [account, setAccount] = useState<any>();
    const history = useHistory();
    const [thread, setThead] = useState<any>();
    const [content, setContent] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingChat, setLoadingChat] = useState<boolean>(false);

    useEffect(() => {
        getAccounts().then(result => {
            if (result) {
                setAccounts(result);
                setAccount(result[0]);
            }
        })
    }, []);

    useEffect(() => {
        if (account) {
            loadData().then();
        }
    }, [account])

    const loadData = async () => {
        if (account?._id) {
            setLoading(true)
            const result = await getMessages(account._id);
            if (result) {
                setRows(result);
            }
            setLoading(false)
        }
    }


    const onDetail = async (thread: any) => {
        setLoadingChat(true);
        setMessages([]);
        setThead(thread);
        const result = await getMessage(thread.threadId, {
            marketplaceId: thread.raw.marketplaceId,
            accountId: account._id
        });
        setMessages(result.messages);
        setLoadingChat(false)
    }

    const onSent = async () => {
        if (!thread) {
            alert("Cần chọn tin nhắn trước");
            return;
        }
        const result = await postMessage({
            accountId: account._id,
            marketplaceId: thread.raw.marketplaceId,
            content: content,
            ...(thread?.raw || {})
        });

    }


    return (
        <div className={'chat-app'}>
            <div className={'chat-app-sidebar'}>
                <select value={account?._id || ""} onChange={(event) => {
                    setAccount(accounts.find(x => x._id === event.target.value));
                }} placeholder={'Chọn tài khoản'} className="form-control form-control-solid">
                    <option value={''}>Chọn tài khoản</option>
                    {
                        accounts.map((item: any, index) => {
                            return (
                                <option key={index} value={item._id}>Tài khoản: {item.name}</option>
                            )
                        })
                    }
                </select>

                <div className={'chat-app-sidebar-threads'}>
                    {
                        loading ? (<p className={'loading-text'}>Đang tải tin nhắn...</p>) : null
                    }
                    {
                        (rows || []).map((item, index) => {
                            return (
                                <div onClick={() => onDetail(item)} className={`theads-item ${item.threadId===thread?.threadId?"active":""}`}>
                                    <a>{item?.customer?.name || ""}
                                        <span>{item?.raw?.formattedArrivalDateOrTime || ""}</span></a>
                                    <p>{item.subject}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="card" id="kt_chat_messenger">

                <div className="card-header" id="kt_chat_messenger_header">

                    <div className="card-title">

                        <div className="d-flex justify-content-center flex-column me-3">
                            <a href="#"
                               className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Nội
                                dung tin nhắn</a>

                        </div>

                    </div>


                </div>


                <div className="card-body" id="kt_chat_messenger_body">
                    <div className="scroll-y me-n5 pe-5 h-300px h-lg-auto">
                        {
                            loadingChat ? (<p className={'loading-text'}>Đang tải nội dung...</p>) : null
                        }
                        {
                            messages.map((message, index) => {
                                return (
                                    <div key={index.toString()}
                                         className={`d-flex ${message?.buyerMessage ? 'justify-content-start' : 'justify-content-end'} mb-5`}>
                                        <div
                                            className={`d-flex flex-column ${message?.buyerMessage ? 'align-items-start' : 'align-items-end'}`}>
                                            <div
                                                className="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start">
                                                {message.messageBody}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


                <div className="card-footer pt-4" id="kt_chat_messenger_footer">

                                    <textarea value={content} onChange={(event => setContent(event.target.value))}
                                              className="form-control form-control-flush mb-3"
                                              placeholder="Nhập nội dung..."/>


                    <div className="d-flex flex-stack">

                        <button onClick={onSent} className="btn btn-primary" type="button"
                                data-kt-element="send">Gửi
                        </button>

                    </div>

                </div>

            </div>
        </div>
    )
}
