import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Action from "../components/Action";
import {DatePicker, Form, Input, Popconfirm, Radio, Select, Spin} from "antd";
import moment from "moment";
import {exportJson, getAccounts, getOrders, postTracking} from "../services/app.service";
import produce from "immer";

const {RangePicker}: any = DatePicker;
const carriers = [{
    "carrier": "USPS",
    "services": ["USPS Bound Printed Matter", "USPS First Class", "USPS First Class International", "USPS First Class Mail Envelopes", "USPS Media Mail", "USPS Parcel Post", "USPS Parcel Select", "USPS Priority Mail", "USPS Priority Mail Cubic", "USPS Priority Mail Express", "USPS Priority Mail Express International", "USPS Priority Mail International", "USPS Standard Mail Envelopes"]
}, {
    "carrier": "FedEx",
    "services": ["FedEx 2Day", "FedEx 2Day A.M.", "FedEx Express Saver", "FedEx Ground", "FedEx Home Delivery", "FedEx Priority Overnight", "FedEx Standard Overnight"]
}, {
    "carrier": "UPS",
    "services": ["UPS 2nd Day Air", "UPS 3 Day Select", "UPS Ground", "UPS i-parcel International Select", "UPS Next Day Air", "UPS Next Day Air Saver"]
}, {
    "carrier": "4PX",
    "services": ["4PX-Global Express", "4PX-PostLink Economy large letter Ordinary Mail-Li", "4PX-PostLink Economy Registered Mail-Li", "4PX-PostLink Economy Registered Mail-NOLi", "4PX-PostLink Economy SRM Registered Mail-Li", "4PX-PostLink Economy2 Ordinary Mail-Li", "4PX-PostLink Economy2 Registered Mail-Li", "4PX-PostLink priority Registered Mail-Li", "4PX-PostLink priority Registered Mail-NOLi", "4PX-PostLink Standard large letter Ordinary Mail-Li", "4PX-PostLink Standard Ordinary Mail-Li", "4PX-PostLink Standard Registered Mail-Li", "4PX-PostLink Standard Registered Mail-NOLi", "4PX-PostLink Standard2 Registered Mail-Li", "ED-4PX-PostLink Economy2 Registered", "NX-4PX-PostLink Standard Ordinary", "PY/PX-4PX-PostLink priority Registered"]
}, {"carrier": "A-1", "services": []}, {"carrier": "AAA Cooper", "services": []}, {
    "carrier": "ABF",
    "services": []
}, {"carrier": "APC Postal Logistics", "services": []}, {
    "carrier": "Asendia",
    "services": []
}, {"carrier": "BOMBINO EXPRESS", "services": []}, {"carrier": "Best Buy", "services": []}, {
    "carrier": "Blue Package",
    "services": []
}, {
    "carrier": "Buylogic",
    "services": ["BuylogicE packetNonSensitive", "BuylogicE packetSensitive", "BuylogicEco packetNonSensitive", "BuylogicEco packetSensitive", "BuylogicEMS", "BuylogicL packetNonRegister", "BuylogicL packetRegister", "BuylogicP packetNonRegister", "BuylogicP packetRegister", "BuylogicT packetNonRegister", "BuylogicT packetRegister", "BuylogicTL packetNonRegister", "BuylogicTL packetRegister"]
}, {"carrier": "CEVA", "services": []}, {"carrier": "CNE", "services": []}, {
    "carrier": "Canada Post",
    "services": []
}, {
    "carrier": "China Post",
    "services": ["China Post e-Courier Packet", "China Post e-Courier Priority", "China Post e-EMS", "China Post ePacket", "China Post Ordinary Airmail", "China Post Registered Airmail"]
}, {
    "carrier": "Chukou1",
    "services": ["CK1 Clothing Line", "CK1 Economy Line (built-in battery)", "CK1 Economy Line (general cargo)", "CK1 Large Item Line", "CK1 Special Line with Sea Freight Service", "CK1 Standard Line (built-in battery)", "CK1 Standard Line (built-in battery)-Untrack", "CK1 Standard Line (general cargo)", "CK1 Super Line (built-in battery)", "CK1 Super Line (general cargo)"]
}, {"carrier": "Conway", "services": []}, {
    "carrier": "DHL",
    "services": ["DHL Express", "International Mail", "Parcel"]
}, {
    "carrier": "DHL eCommerce",
    "services": ["Packet International Standard", "PKD-Packet International Standard", "PLG-Parcel International Direct Goods", "PLT-Parcel International Direct-Standard", "PPS-Packet Plus International"]
}, {"carrier": "Ecom Shipping Solutions Pvt Ltd", "services": []}, {
    "carrier": "Equick",
    "services": []
}, {"carrier": "Estes", "services": []}, {"carrier": "FedEx SmartPost", "services": []}, {
    "carrier": "Fedex Freight",
    "services": []
}, {"carrier": "First Mile", "services": []}, {"carrier": "Flyt", "services": []}, {
    "carrier": "HUAHAN Logistics",
    "services": ["HH Cosmetics specialpak line", "HH Easy post ordinary mail Special", "HH Ecomomic smartpak line Special", "HH Efficient post ordinary mail Special", "HH Efficient post registered mail Special", "HH Free post ordinary mail General", "HH Free post registered mail Special", "HH Global economic Line General", "HH Global special goods Line", "HH Joy on time express", "HH Kichen and outdoor knives specialpak line", "HH Priority smartpak line Special", "HH Pure electric specialpak line"]
}, {"carrier": "Hongkong Post", "services": ["Air-Parcel", "SpeedPost"]}, {
    "carrier": "Hunter Logistics",
    "services": []
}, {"carrier": "India Post", "services": []}, {
    "carrier": "JCEX",
    "services": ["CA International Express", "EUB", "Europe-DHL", "Europe-DPD", "Europe-UPS", "Fedex IE", "Fedex IP", "Jia-Packet", "JP International Express", "TNT", "UPS Worldwide Expedited", "UPS Worldwide Saver", "US International Express"]
}, {"carrier": "JT", "services": []}, {"carrier": "Lasership", "services": []}, {
    "carrier": "Newgistics",
    "services": []
}, {"carrier": "OSM", "services": []}, {"carrier": "Old Dominion", "services": []}, {
    "carrier": "OnTrac",
    "services": ["OnTrac Ground"]
}, {"carrier": "Pilot Freight", "services": []}, {"carrier": "R+L", "services": []}, {
    "carrier": "Roadrunner",
    "services": []
}, {"carrier": "Royal Mail", "services": []}, {
    "carrier": "SF Express",
    "services": ["E-Commerce Express CD", "E-Commerce Express Priority", "E-Commerce Express Standard", "E-Commerce Ordinary Parcel-BPost", "E-Commerce Ordinary Parcel-Economy", "E-Commerce Ordinary Parcel-Special Line", "E-Commerce Ordinary Parcel-Standard", "E-Commerce Registered Parcel-Economy", "E-Commerce Registered Parcel-LA", "E-Commerce Registered Parcel-LU Post", "E-Commerce Registered Parcel-Special Line", "E-Commerce Registered Parcel-Standard", "Economy Express", "Standard Express"]
}, {"carrier": "SFC", "services": ["SFC Global Line-no battery", "SFC Global Line-with battery"]}, {
    "carrier": "Saia",
    "services": []
}, {"carrier": "Sendle", "services": ["Standard"]}, {
    "carrier": "ShipGlobal",
    "services": []
}, {
    "carrier": "Singapore Post",
    "services": ["EMS", "Ordinary Mail", "Registered Mail"]
}, {"carrier": "South Eastern Freight Lines", "services": []}, {
    "carrier": "StreamLite",
    "services": []
}, {"carrier": "Sunyou", "services": []}, {"carrier": "Topyou", "services": []}, {
    "carrier": "UBI",
    "services": []
}, {"carrier": "UPS Freight", "services": []}, {
    "carrier": "UPS Mail Innovations",
    "services": []
}, {"carrier": "Urban Express", "services": []}, {
    "carrier": "WanbExpress",
    "services": ["WANB EXPRESS", "WANB Post Economy", "WANB Post Smart", "WANB Semi Track", "WANB Smart Track"]
}, {"carrier": "Watkins and Shepard", "services": []}, {"carrier": "XPO Freight", "services": []}, {
    "carrier": "YDH",
    "services": ["YDH Economy Service", "YDH Express Service", "YDH Standard Service"]
}, {
    "carrier": "Yanwen",
    "services": ["Yanwen Air Economy Mail General", "Yanwen Air Economy Mail Special ", "Yanwen Air Register Mail General", "Yanwen Air Register Mail Special", "Yanwen Air Track Packet General", "Yanwen Air Track Packet Special", "Yanwen Air Untrack Packet General", "Yanwen Air Untrack Packet Special", "Yanwen Direct Line Express General", "Yanwen Direct Line Express Special"]
}, {"carrier": "Yellow Freight", "services": []}, {
    "carrier": "Yun Express",
    "services": ["YunExpress DE SRM Direct Line", "YunExpress Global Direct line (standard )-Tracked", "YunExpress Global Direct line (standard)-Untracked", "YunExpress Global Direct line non Battery-Tracked", "YunExpress Global Direct line non Battery-Untracked", "YunExpress Global Direct line with Battery-Tracked", "YunExpress Global Direct line with Battery-Untracked", "YunExpress JP Direct Line", "YunExpress ME Direct Line", "YunExpress ME Direct line-DDP"]
}, {"carrier": "ePost Global", "services": []}, {"carrier": "uShip", "services": []}
];

export default function Order(props: any) {
    const [rows, setRows] = useState<Array<any>>([]);
    const [rowsView, setRowsView] = useState<Array<any>>([]);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState<any>({});
    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        loadData().then();
    }, [page]);

    useEffect(() => {
        setRowsView(getItemsFilter(rows));
    }, [filter, rows]);

    useEffect(() => {
        getAccounts().then(result => {
            if (result) {
                setAccounts(result);
            }
        })
    }, []);

    const loadData = async () => {
        setLoading(true)
        const result = await getOrders(page);
        if (result) {
            setRows(result);
        }
    }


    const onRemove = (row: any) => {

    }

    const onExcel = async () => {
        const data: Array<any> = [];

        rows.map(item => {
            item?.products.map((product: any, indexProduct: number) => {
                data.push({
                    orderNumber: item.orderId,
                    sales: `${item.total}${item.totalUnit}`,
                    quantity: item.quantity,
                    title: product?.title,
                    sku: product?.sku,
                    custom: (product?.variations || []).map((v: any) => `${v.property}: ${v.value}`).join(' '),
                    'Ship date': moment(item.shipDate).format('DD/MM/YYYY'),
                    'Order date': moment(item.orderDate).format('DD/MM/YYYY'),
                    Shipping: `${item?.shipMethod} ${item?.shipPrice}$`,
                    address: `${item?.shipAddress?.line1} ${item?.shipAddress?.line2}, ${item?.shipAddress?.city}, ${item?.shipAddress?.stateOrRegion}, ${item?.shipAddress?.postalCode},${item?.shipAddress?.countryCode}`
                })
            })

        })

        await exportJson('order_exported', data);
    }

    const onAddTracking = async (row: any) => {
        const result = await postTracking({
            orderId: row.orderId,
            orderItems: row.raw.orderItems,
            trackingId: row.trackingId || "",
            trackingMethod: (row?.trackingMethod === 'other' ? (row.trackingMethodOther || '') : (row.trackingMethod || "")),
            trackingService: (row?.trackingService === 'other' ? (row.trackingServiceOther || '') : (row.trackingService || "")),
            accountId: row.account._id,
        });
        alert("Tracking thành công, trạng thái sẽ được cập nhật trong ít giờ tới!")
    }

    const getItemsFilter = (items: Array<any>) => {
        return items.filter(item => {
            let filterResult = true;
            if (filter?.orderId && filterResult) {
                filterResult = item?.orderId?.toString()?.includes(filter?.orderId || "");
            }

            if (filter?.accountId?.length && filterResult) {
                filterResult = !!(filter?.accountId || []).includes((item?.account?._id || ""));
            }

            if (filter?.orderDate?.length === 2 && filterResult) {
                filterResult = moment(item?.orderDate).isBetween(filter?.orderDate[0], filter?.orderDate[1], 'days', '[]');
            }

            if (filter?.status && filterResult) {
                const status = (item?.raw?.orderStatus?.OrderStatus || item.status).toLowerCase();
                filterResult = status === filter?.status;
            }

            return filterResult;
        });
    }


    return (
        <>
            <div className="card">

                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Quản lý đơn hàng</span>
                    </h3>
                    <div className="card-toolbar">
                        <a onClick={onExcel} className="btn btn-primary fw-bolder fs-7">Xuất excel</a>
                    </div>
                </div>


                <div className="card-body">

                    <Form form={form} onValuesChange={values => {
                        setFilter(form.getFieldsValue());
                    }}>
                        <div className={'filter-items'}>
                            <div className={'filter-item'} style={{flex: 1}}>
                                <Form.Item name={'orderId'}>
                                    <Input placeholder={"Tìm mã đơn hàng..."}/>
                                </Form.Item>
                            </div>
                            <div className={'filter-item'}>
                                <Form.Item name={'accountId'}>
                                    <Select placeholder={"Chọn tài khoản"} mode={'multiple'}>
                                        {accounts.map((value, index) => {
                                            return (
                                                <Select.Option value={value._id}>{value.name}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className={'filter-item'}>
                                <Form.Item name={'orderDate'}>
                                    <RangePicker/>
                                </Form.Item>
                            </div>
                            <div className={'filter-item'}>
                                <Form.Item name={'status'}>
                                    <Select allowClear={true} placeholder={"Trạng thái"}>
                                        <Select.Option value={''}>Tất cả trạng thái</Select.Option>
                                        <Select.Option value={'unshipped'}>Chưa ship</Select.Option>
                                        <Select.Option value={'shipped'}>Hoàn thành</Select.Option>
                                        <Select.Option value={'canceled'}>Đã hủy</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>

                    <div className="table-responsive">
                        <table className="table align-middle border-gray-100" id="kt_advance_table_template_4">
                            <thead>
                            <tr className="text-start fw-bolder text-black text-uppercase fs-7 border-gray-100 border-bottom-1">
                                <td className="py-5">Mã đơn</td>
                                <td className="py-5">Sản phẩm</td>
                                <td className="py-5">Khách hàng</td>
                                <td className="py-5">SL</td>
                                <td className="py-5">Đơn giá</td>
                                <td className="py-5">Thuế</td>
                                <td className="py-5">Ship</td>
                                <td className="py-5">Tổng tiền</td>
                                <td className="py-5">Ngày tạo</td>
                                <td className="pe-0 text-end py-5"/>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !rows?.length ? (
                                    <tr>
                                        <td colSpan={10}>Đang tải...</td>
                                    </tr>
                                ) : null
                            }
                            {
                                rowsView.map((item, index) => {
                                    const trackingId = item.trackingId || ((item?.raw?.packages ? item?.raw?.packages[0]?.TrackingId : null) || "");
                                    const status = (item?.raw?.orderStatus?.OrderStatus || item.status).toLowerCase();
                                    return (
                                        <tr key={index.toString()}>
                                            <td>
                                                <div>{item.orderId} <span
                                                    className={'account-view'}>{item?.account?.name || ""}</span></div>
                                            </td>
                                            <td>
                                                {item?.products.map((product: any, indexProduct: number) => {
                                                    return (
                                                        <div data-id={item._id} data-index={indexProduct}
                                                             key={indexProduct} className={'product-view'}>
                                                            <img onClick={() => {
                                                                window.open(`https://www.amazon.com/gp/product/${product.asin}`)
                                                            }} src={product?.image}/>
                                                            <div>
                                                                <h3 style={{cursor: 'pointer'}} onClick={() => {
                                                                    window.open(`https://www.amazon.com/gp/product/${product.asin}`)
                                                                }}>{product?.title}</h3>
                                                                <ul>
                                                                    <li><b>Sku:</b> {product?.sku}
                                                                        <b>Qty:</b> {product?.quantity}</li>
                                                                    <li>
                                                                        <b>Price:</b> {product?.price} {product?.priceUnit}
                                                                    </li>
                                                                    <li dangerouslySetInnerHTML={{__html: (product?.variations || []).map((v: any) => `<b>${v.property}:</b> ${v.value}`).join(' ')}}/>
                                                                    <li>
                                                                        {
                                                                            (item?.raw?.orderItems[indexProduct]?.ItemCustomizations?.ModificationGroups[0]?.Modifications || []).map((itemCustom: any, indexCustom: number) => {
                                                                                return (
                                                                                    <div key={indexCustom.toString()}>
                                                                                        <b>{itemCustom?.Name || ""}:</b> {itemCustom?.Value || ""}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </td>
                                            <td>
                                                <ul className={'customer-view'}>
                                                    <li><b>Name:</b> {item?.shipAddress?.name}</li>
                                                    <li><b>Label:</b> {item?.shipAddress?.label}</li>
                                                    <li>
                                                        <b>Line1-2:</b> {item?.shipAddress?.line1} {item?.shipAddress?.line2}
                                                    </li>
                                                    <li><b>City:</b> {item?.shipAddress?.city}
                                                        <b>State:</b> {item?.shipAddress?.stateOrRegion}</li>
                                                    <li><b>Zip:</b> {item?.shipAddress?.postalCode}
                                                        <b>Country:</b> {item?.shipAddress?.countryCode}</li>

                                                </ul>
                                            </td>
                                            <td>{item.quantity}</td>
                                            <td>{item.price}{item.priceUnit}</td>
                                            <td>{item.priceTax}{item.priceTaxUnit}</td>
                                            <td>
                                                <ul className={'ship-view'}>
                                                    <li><b>Hạn ship:</b> {moment(item.shipDate).format('DD/MM/YYYY')}
                                                    </li>
                                                    <li><b>Vận
                                                        chuyển:</b> {moment((item?.raw?.latestDeliveryDate || 0) * 1000).format('DD/MM/YYYY')}
                                                    </li>
                                                    <li><b>Giá:</b> {item?.shipPrice}$ <b>Loại:</b> {item?.shipMethod}
                                                    </li>
                                                    {
                                                        trackingId ? (
                                                            <>
                                                                <li>
                                                                    <b>TrackCode: </b> {trackingId}
                                                                </li>
                                                                <li>
                                                                    <b>Track
                                                                        Method: </b> {item.trackingMethod || ((item?.raw?.packages ? item?.raw?.packages[0]?.Carrier : null) || "")}
                                                                </li>
                                                                <li>
                                                                    <b>Track
                                                                        Service: </b> {item.trackingService || ((item?.raw?.packages ? item?.raw?.packages[0]?.ShippingService : null) || "")}
                                                                </li>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <li>
                                                                    <b>TrackCode: </b>
                                                                    <input
                                                                        onChange={event => {
                                                                            setRows(produce(draft => {
                                                                                const index = rows.findIndex(x => x._id === item._id);
                                                                                draft[index].trackingId = event.target.value;
                                                                            }))
                                                                        }}
                                                                        disabled={((item?.raw?.packages ? item?.raw?.packages[0]?.TrackingId : null) || "")}
                                                                        value={item.trackingId || ((item?.raw?.packages ? item?.raw?.packages[0]?.TrackingId : null) || "")}
                                                                        className={'form-control form-control-sm'}
                                                                        placeholder={'Mã tracking'}/>
                                                                </li>
                                                                <li>
                                                                    <b>Track Method: </b>
                                                                    <select onChange={event => {
                                                                        setRows(produce(draft => {
                                                                            const index = rows.findIndex(x => x._id === item._id);
                                                                            draft[index].trackingMethod = event.target.value;
                                                                        }))
                                                                    }} value={item.trackingMethod || ""}
                                                                            className={'form-control form-control-sm'}>
                                                                        <option value={''} key={'-1'}>Chọn tracking
                                                                            method
                                                                        </option>
                                                                        {
                                                                            carriers.map((carrier, carrierIndex) => {
                                                                                return (
                                                                                    <option value={carrier.carrier}
                                                                                            key={carrierIndex.toString()}>{carrier.carrier}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                        <option value={'other'} key={'other'}>Other
                                                                        </option>
                                                                    </select>
                                                                    {
                                                                        item.trackingMethod == 'other' ? (
                                                                            <input
                                                                                onChange={event => {
                                                                                    setRows(produce(draft => {
                                                                                        const index = rows.findIndex(x => x._id === item._id);
                                                                                        draft[index].trackingMethodOther = event.target.value;
                                                                                    }))
                                                                                }}
                                                                                value={item.trackingMethodOther}
                                                                                className={'form-control form-control-sm'}
                                                                                placeholder={'Track Method Khác'}/>
                                                                        ) : null
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <b>Track Service: </b>
                                                                    <select onChange={event => {
                                                                        setRows(produce(draft => {
                                                                            const index = rows.findIndex(x => x._id === item._id);
                                                                            draft[index].trackingService = event.target.value;
                                                                        }))
                                                                    }} value={item.trackingService || ""}
                                                                            className={'form-control form-control-sm'}>
                                                                        <option value={''}>Chọn tracking service
                                                                        </option>
                                                                        {
                                                                            carriers.filter(carrier => (item.trackingMethod && item.trackingMethod !== "other" ? carrier.carrier === item.trackingMethod : true)).map((carrier, carrierIndex) => {
                                                                                return (
                                                                                    <optgroup
                                                                                        key={carrierIndex.toString()}
                                                                                        label={carrier.carrier}>
                                                                                        {
                                                                                            carrier.services.map((service, serviceIndex) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={serviceIndex.toString()}
                                                                                                        value={service}>{service}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <option value={'other'}
                                                                                                key={'other' + carrierIndex}>Other
                                                                                        </option>
                                                                                    </optgroup>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {
                                                                        item.trackingService == 'other' ? (
                                                                            <input
                                                                                onChange={event => {
                                                                                    setRows(produce(draft => {
                                                                                        const index = rows.findIndex(x => x._id === item._id);
                                                                                        draft[index].trackingServiceOther = event.target.value;
                                                                                    }))
                                                                                }}
                                                                                value={item.trackingServiceOther}
                                                                                className={'form-control form-control-sm'}
                                                                                placeholder={'Track Service Khác'}/>
                                                                        ) : null
                                                                    }
                                                                </li>
                                                                <Popconfirm onConfirm={() => onAddTracking(item)}
                                                                            title={'Gửi tracking lên amazon?'}>
                                                                    <Action type={'text'} text={'Gửi lên Amazon'}/>
                                                                </Popconfirm>
                                                            </>
                                                        )
                                                    }
                                                </ul>
                                            </td>
                                            <td>{item.total}{item.totalUnit}</td>
                                            <td>
                                                {moment(item.orderDate).format('DD/MM/YYYY')}
                                                <div className={`status-view ${status}`}>
                                                    <b>
                                                        {status === 'unshipped' ? 'Chưa ship' : ''}
                                                        {status === 'shipped' ? 'Hoàn thành' : ''}
                                                        {status === 'canceled' ? 'Đã hủy' : ''}
                                                    </b>
                                                </div>
                                            </td>
                                            <td className="pe-0 text-end">
                                                {/*<Popconfirm onConfirm={() => onRemove(item)} title={'Confirm delete?'}>*/}
                                                {/*    <Action type={'remove'}/>*/}
                                                {/*</Popconfirm>*/}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </>
    )
}
