import Environment from "../environment";
import HttpService from "./http.service";
import * as XLSX from 'xlsx';

export async function postAuth(user: any) {
    return HttpService.post(`${Environment.host}/auth`, user);
}


export async function getOrders(page: number = 0) {
    return HttpService.get(`${Environment.host}/order`, {page});
}

export async function getAccounts(page: number = 0) {
    return HttpService.get(`${Environment.host}/account`, {page});
}

export async function getAccount(_id: string) {
    return HttpService.get(`${Environment.host}/account/${_id}`);
}

export async function postAccount(data: any) {
    return HttpService.post(`${Environment.host}/account`, data);
}

export async function putAccount(id: string, data: any) {
    return HttpService.put(`${Environment.host}/account/${id}`, data);
}

export async function postHistory(history: any) {
    return HttpService.post(`${Environment.host}/history/`, history);
}


export async function getMessages(accountId: string = '') {
    return HttpService.get(`${Environment.host}/messages`, {accountId});
}

export async function postMessage(data: any) {
    return HttpService.post(`${Environment.host}/messages`, data);
}

export async function postTracking(data: any) {
    return HttpService.post(`${Environment.host}/tracking`, data);
}

export async function getMessage(id: string, params: any) {
    return HttpService.get(`${Environment.host}/messages/${id}`, params);
}

export async function exportJson(fileName: string, data: Array<any>) {
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, fileName + '_' + new Date().getTime().toString() + '.xlsx');
}