import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Action from "../components/Action";
import {Popconfirm, Radio, Spin} from "antd";
import moment from "moment";
import {getOrders, postTracking} from "../services/app.service";
import produce from "immer";

export default function Tracking(props: any) {
    const [rows, setRows] = useState<Array<any>>([]);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData().then();
    }, [page]);

    const loadData = async () => {
        setLoading(true)
        const result = await getOrders(page);
        if (result) {
            setRows(result);
        }
    }


    const onAddTracking = async (row: any) => {
        const result = await postTracking({
            orderId: row.orderId,
            orderItems: row.raw.orderItems,
            trackingId: row.trackingId || "",
            trackingMethod: row.trackingMethod || "",
            accountId: row.account._id,
        });
        alert("Tracking thành công, trạng thái sẽ được cập nhật trong ít giờ tới!")
    }


    return (
        <>
            <div className="card">

                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Quản lý tracking</span>
                    </h3>
                    <div className="card-toolbar">
                        {/*<a className="btn btn-primary fw-bolder fs-7">Cập nhật</a>*/}
                    </div>
                </div>


                <div className="card-body">

                    <div className="table-responsive">
                        <table className="table align-middle border-gray-100" id="kt_advance_table_template_4">
                            <thead>
                            <tr className="text-start fw-bolder text-black text-uppercase fs-7 border-gray-100 border-bottom-1">
                                <td className="py-5">Mã đơn</td>
                                {/*<td className="py-5">Tài khoản</td>*/}
                                {/*<td className="py-5">Sản phẩm</td>*/}
                                {/*<td className="py-5">Khách hàng</td>*/}
                                {/*<td className="py-5">Ship</td>*/}
                                <td className="py-5">Ship Method</td>
                                <td className="py-5">Tracking</td>
                                <td className="py-5">Trạng thái</td>
                                <td className="pe-0 text-end py-5"/>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !rows?.length ? (
                                    <tr>
                                        <td colSpan={7}>Đang tải...</td>
                                    </tr>
                                ) : null
                            }
                            {
                                rows.map((item, index) => (
                                    <tr key={index.toString()}>
                                        <td>
                                            <div>{item.orderId}
                                                {/*<span className={'account-view'}>{item?.account?.name || ""}</span>*/}
                                            </div>
                                        </td>
                                        {/*<td>*/}
                                        {/*    {item?.products.map((product: any, indexProduct: number) => {*/}
                                        {/*        return (*/}
                                        {/*            <div key={indexProduct} className={'product-view'}>*/}
                                        {/*                <img src={product?.image}/>*/}
                                        {/*                <div>*/}
                                        {/*                    <h3>{product?.title}</h3>*/}
                                        {/*                    <ul>*/}
                                        {/*                        <li><b>Sku:</b> {product?.sku}*/}
                                        {/*                            <b>Qty:</b> {product?.quantity}</li>*/}
                                        {/*                        <li><b>Price:</b> {product?.price} {product?.priceUnit}*/}
                                        {/*                        </li>*/}
                                        {/*                        <li dangerouslySetInnerHTML={{__html: (product?.variations || []).map((v: any) => `<b>${v.property}:</b> ${v.value}`).join(' ')}}/>*/}
                                        {/*                    </ul>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        )*/}
                                        {/*    })}*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*    <ul className={'customer-view'}>*/}
                                        {/*        <li><b>Name:</b> {item?.shipAddress?.name}</li>*/}
                                        {/*        <li><b>Label:</b> {item?.shipAddress?.label}</li>*/}
                                        {/*        <li>*/}
                                        {/*            <b>Line1-2:</b> {item?.shipAddress?.line1} {item?.shipAddress?.line2}*/}
                                        {/*        </li>*/}
                                        {/*        <li><b>City:</b> {item?.shipAddress?.city}*/}
                                        {/*            <b>State:</b> {item?.shipAddress?.stateOrRegion}</li>*/}
                                        {/*        <li><b>Zip:</b> {item?.shipAddress?.postalCode}*/}
                                        {/*            <b>Country:</b> {item?.shipAddress?.countryCode}</li>*/}

                                        {/*    </ul>*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*    <ul className={'ship-view'}>*/}
                                        {/*        <li><b>Hạn ship:</b> {moment(item.shipDate).format('DD/MM/YYYY')}</li>*/}
                                        {/*        <li><b>Loại:</b> {item?.shipMethod}</li>*/}
                                        {/*        <li><b>Giá:</b> {item?.shipPrice}$</li>*/}
                                        {/*    </ul>*/}

                                        {/*</td>*/}
                                        <td>
                                            <select onChange={event => {
                                                setRows(produce(draft => {
                                                    draft[index].trackingMethod = event.target.value;
                                                }))
                                            }} value={item.trackingMethod || ""}
                                                    className={'form-control form-control-sm'}>
                                                <option value={''}>Chọn tracking method</option>
                                                <option>USPS</option>
                                                <option>DHL eCommerce</option>
                                                <option>Canada Post</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                onChange={event => {
                                                    setRows(produce(draft => {
                                                        draft[index].trackingId = event.target.value;
                                                    }))
                                                }}
                                                disabled={((item?.raw?.packages ? item?.raw?.packages[0]?.TrackingId : null) || "")}
                                                value={item.trackingId || ((item?.raw?.packages ? item?.raw?.packages[0]?.TrackingId : null) || "")}
                                                className={'form-control form-control-sm'}
                                                placeholder={'Mã tracking'}/>
                                        </td>

                                        <td>
                                            {moment(item.orderDate).format('DD/MM/YYYY')}
                                            <div className={`status-view ${item.status}`}>
                                                <b>
                                                    {item.status === 'unshipped' ? 'Chưa ship' : ''}
                                                    {item.status === 'shipped' ? 'Hoàn thành' : ''}
                                                </b>
                                            </div>
                                        </td>
                                        <td className="pe-0 text-end">
                                            {item.status === 'unshipped' ? (
                                                <Popconfirm onConfirm={() => onAddTracking(item)}
                                                            title={'Gửi tracking lên amazon?'}>
                                                    <Action type={'text'} text={'Gửi lên Amazon'}/>
                                                </Popconfirm>
                                            ) : ''}

                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </>
    )
}
