import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Action from "../../components/Action";
import {Popconfirm, Radio, Spin} from "antd";
import moment from "moment";
import {getAccounts, getOrders} from "../../services/app.service";

export default function Account(props: any) {
    const [rows, setRows] = useState<Array<any>>([]);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData().then();
    }, [page]);

    const loadData = async () => {
        setLoading(true)
        const result = await getAccounts(page);
        if (result) {
            setRows(result);
        }
    }


    const onRemove = (row: any) => {

    }


    return (
        <>
            <div className="card">

                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">Quản lý tài khoản</span>
                    </h3>
                    <div className="card-toolbar">
                        <Link to={"/account-add"} className="btn btn-primary fw-bolder fs-7">Thêm mới</Link>
                    </div>
                </div>


                <div className="card-body">

                    <div className="table-responsive">
                        <table className="table align-middle border-gray-100" id="kt_advance_table_template_4">
                            <thead>
                            <tr className="text-start fw-bolder text-black text-uppercase fs-7 border-gray-100 border-bottom-1">
                                <td className="py-5">STT</td>
                                <td className="py-5">Tên</td>
                                <td className="py-5">Máy VPS</td>
                                <td className="py-5">TK Chrome</td>
                                <td className="py-5">Trạng thái</td>
                                <td className="py-5">Ngày tạo</td>
                                <td className="py-5">Cập nhật</td>
                                <td className="pe-0 text-end py-5"></td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !rows?.length ? (
                                    <tr>
                                        <td colSpan={8}>Đang tải...</td>
                                    </tr>
                                ) : null
                            }
                            {
                                rows.map((item, index) => (
                                    <tr key={index.toString()}>
                                        <td>{index + 1}</td>
                                        <td>{item?.name}</td>
                                        <td>{item?.meta?.host}</td>
                                        <td>{item?.meta['profile-directory']}</td>
                                        <td>{item.status}</td>
                                        <td>{moment(item.createdAt).format('HH:mm DD/MM/YYYY')}</td>
                                        <td>{moment(item.updatedAt).format('HH:mm DD/MM/YYYY')}</td>
                                        <td className="pe-0 text-end">
                                            <Action onClick={() => {
                                                history.push(`/account-edit/${item._id}`);
                                            }} type={'edit'}/>
                                            <Popconfirm onConfirm={() => onRemove(item)} title={'Confirm delete?'}>
                                                <Action type={'remove'}/>
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </>
    )
}
