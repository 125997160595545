import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Form, message, Popconfirm, Radio} from "antd";

import {getAccount, getAccounts, postAccount, putAccount} from "../../services/app.service";

export default function AccountEdit(props: any) {
    let {id}: any = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [data, setData] = useState<any>();

    useEffect(() => {
        if (id) {
            getAccount(id).then(value => {
                setData(value);
                form.setFieldsValue(value);
            });
        }
    }, []);


    const onSubmit = (values: any) => {
        const data = values;

        if (id) {
            putAccount(id, data).then(value => {
                if (value) {
                    message.success("Cập nhật tài khoản thành công!");
                    history.goBack();
                }
            });
        } else {
            if (!data.email || !data.name) {
                return message.error("Cần nhập tên và email!");
            }
            postAccount(data).then(value => {
                if (value) {
                    message.success("Thêm tài khoản thành công!");
                    history.goBack();
                }
            });
        }
    }

console.log(id)
    return (!id || id && data) ? (
        <>
            <div className="card">
                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder text-dark">
                            {id ? 'Cập nhật tài khoản' : 'Thêm mới tài khoản'}
                        </span>
                    </h3>
                </div>
                <Form initialValues={data} form={form} onFinish={onSubmit} className="form d-flex flex-center">
                    <div className="card-body">

                        <div className="row mb-8">
                            <label className="col-lg-3 col-form-label">Tên</label>
                            <div className="col-lg-9">
                                <div className="spinner spinner-sm spinner-primary spinner-right">
                                    <Form.Item name={'name'}>
                                        <input className="form-control form-control-lg form-control-solid" type="text"/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-8">
                            <label className="col-lg-3 col-form-label">Email</label>
                            <div className="col-lg-9">
                                <div className="spinner spinner-sm spinner-primary spinner-right">
                                    <Form.Item name={'email'}>
                                        <input className="form-control form-control-lg form-control-solid" type="email"/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-8">
                            <label className="col-lg-3 col-form-label">Trạng thái</label>
                            <div className="col-lg-9">
                                <div className="spinner spinner-sm spinner-primary spinner-right">
                                    <Form.Item name={'status'} initialValue={'active'}>
                                        <select className="form-control form-control-lg form-control-solid">
                                            <option value={'active'}>Hoạt động</option>
                                            <option value={'inactive'}>Vô hiệu hóa</option>
                                        </select>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-8">
                            <label className="col-lg-3 col-form-label">Địa chỉ VPS</label>
                            <div className="col-lg-9">
                                <div className="spinner spinner-sm spinner-primary spinner-right">
                                    <Form.Item name={['meta', 'host']}>
                                        <input className="form-control form-control-lg form-control-solid" type="text"/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-8">
                            <label className="col-lg-3 col-form-label">Thư mục Chrome</label>
                            <div className="col-lg-9">
                                <div className="spinner spinner-sm spinner-primary spinner-right">
                                    <Form.Item name={['meta', 'user-data-dir']}
                                               initialValue={"C:\\Users\\Administrator\\AppData\\Local\\Google\\Chrome\\User Data"}>
                                        <input className="form-control form-control-lg form-control-solid" type="text"/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-8">
                            <label className="col-lg-3 col-form-label">Tài khoản Chrome</label>
                            <div className="col-lg-9">
                                <div className="spinner spinner-sm spinner-primary spinner-right">
                                    <Form.Item name={['meta', 'profile-directory']}
                                               initialValue={"Default"}>
                                        <input className="form-control form-control-lg form-control-solid" type="text"/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-12 center">
                                <Popconfirm onConfirm={e => form.submit()} title={'Save Changes?'}>
                                    <button type="button" className="btn btn-primary fw-bolder px-6 py-3 me-3">
                                        Lưu lại
                                    </button>
                                </Popconfirm>
                                <button onClick={event => history.goBack()} type="reset"
                                        className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3">Hủy
                                    bỏ
                                </button>
                            </div>
                        </div>

                    </div>
                </Form>

            </div>
        </>
    ) : null
}
